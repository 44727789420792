<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Admin - Manage Colours</h1>
          <v-row>
            <v-col class="col-12 col-md-4">
              <v-text-field
                label="Search"
                v-model="searchTerm"
                clearable />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-btn
                @click="addColour = true"
                small
                class="float-right">
                Add Colour
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-8">
              <v-simple-table
                fixed-header
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Colour</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(colour, fIndex) in filteredColours"
                      :key="fIndex"
                    >
                      <td>{{ colour.text }}</td>
                      <td>
                        <v-btn small @click="archive(fIndex)">
                          Archive
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="modal"
        v-if="addColour">
        <v-icon
          class="close"
          @click="addColour = false"
        >mdi-close-circle</v-icon>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newColour"
                label="Colour" />
            </v-col>
            <v-col class="pt-8">
              <v-btn
                @click="saveNewColour" small>Save Colour</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminColours',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        colours: [],
        filteredColours: [],
        searchTerm: null,
        addColour: false,
        newColour: null,
      };
    },
    watch: {
      searchTerm() {
        this.filterColours();
      },
    },
    methods: {
      archive(index) {
        const colour = this.filteredColours[index];
        axios.get(`/colours/archive/${colour.value}.json?token=${this.token}`)
          .then((response) => {
            this.colours = response.data;
            this.filteredColours = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      saveNewColour() {
        const postData = {};
        postData.colour = this.newColour;
        axios.post(`/colours/create/.json?token=${this.token}`, postData)
          .then((response) => {
            this.colours = response.data;
            this.filteredColours = response.data;
            this.addColour = false;
            this.newColour = null;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      filterColours() {
        this.filteredColours = this.colours;
        if (this.searchTerm) {
          this.filteredColours = this.colours.filter((colour) => colour.text.toLowerCase().includes(
            this.searchTerm.toLowerCase(),
          ));
        }
      },
      getColours() {
        axios.get(`/colours/getAll.json?token=${this.token}&term=${this.searchTerm}`)
          .then((response) => {
            this.colours = response.data;
            this.filteredColours = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getColours();
    },
  };
  </script>
  